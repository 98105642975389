// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/confirmation-route/FireworksAnimation.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/confirmation-route/FireworksAnimation.tsx");
}
// REMIX HMR END

import { useEffect, useRef } from 'react';
import lottie from 'lottie-web/build/player/lottie_light.min.js';
import confirmationFireworksLeft from '../../lottie/confirmation-fireworks-left.json';
import confirmationFireworksRight from '../../lottie/confirmation-fireworks-right.json';
import styles from './FireworksAnimation.module.css';
export function FireworksAnimation() {
  _s();
  const inlineStartFireworks = useRef(null);
  const inlineEndFireworks = useRef(null);
  useEffect(() => {
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      return;
    }
    const anims = [];
    inlineStartFireworks.current && anims.push(lottie.loadAnimation({
      container: inlineStartFireworks.current,
      renderer: 'svg',
      loop: 1,
      autoplay: true,
      animationData: confirmationFireworksLeft
    }));
    inlineEndFireworks.current && anims.push(lottie.loadAnimation({
      container: inlineEndFireworks.current,
      renderer: 'svg',
      loop: 1,
      autoplay: true,
      animationData: confirmationFireworksRight
    }));
    anims.forEach(anim => {
      anim.addEventListener('complete', () => {
        anim.destroy();
      });
    });
    return () => {
      anims.forEach(anim => anim.destroy());
    };
  }, [inlineStartFireworks, inlineEndFireworks]);
  return <>
      <div className={styles.fireworks} ref={inlineStartFireworks} />
      <div className={styles.fireworks} ref={inlineEndFireworks} />
    </>;
}
_s(FireworksAnimation, "PHW0lyEXegytg2IPyJ2faKZVR98=");
_c = FireworksAnimation;
var _c;
$RefreshReg$(_c, "FireworksAnimation");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;